jQuery(document).ready(function ($) {

    //CYBERMÉTRIE - Ajout dataLayer pour campagne Application mobile

    var cybermIdPrev = document.getElementById("btn-swipper-campange-prev");

    if (cybermIdPrev != null) {
        cybermIdPrev.addEventListener("click", function () {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'gaEvent',
                'eventCategory': 'Application don plasma',
                'eventAction': 'Page application',
                'eventLabel': 'Carrousel > Changemenent de slide'
            });
        });
    }

    var cybermIdNext = document.getElementById("btn-swipper-campange-next");
    
    if (cybermIdNext != null) {
        cybermIdNext.addEventListener("click", function () {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'gaEvent',
                'eventCategory': 'Application don plasma',
                'eventAction': 'Page application',
                'eventLabel': 'Carrousel > Changemenent de slide'
            });
        });
    }

});